/*Custom theme colors*/
@use '@angular/material' as mat;
//TODO: update light and darK color
$primary: (
  light: #ffffff,
  dark: #ffffff,
  default: #353839,
  contrast: (
    default: #ffffff
  )
);

$accent: (
  light: #fe7143,
  default: #e65526,
  dark: #9e3817,
  contrast: (
    default: #ffffff,
    dark: #000000
  )
);
.cpq-button-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}
$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Roboto, sans-serif"
);

.cpq-breadcrumb {
  font-size: 12px !important;
  line-height: 15px;
  text-decoration: none !important;
  padding: 15px 0 15px 0;
  .parent {
    color: #071f60 !important;
    font-weight: 600;
  }
  .active {
    color: #494949 !important;
  }
}
.cpq-contact-links {
  color: #071f60 !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
}

.cpq-page-header {
  color: #333333;
  font-size: 30px !important;
  line-height: 37px !important;
  margin-bottom: 0.5em;
}
.cpq-page-sub-header {
  color: #444444 !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
}
.cpq-anchor {
  color: #071f60 !important;
}
.mat-step-icon {
  height: 38px !important;
  width: 38px !important;
  background-color: #e65526 !important;
}

//Overriding Material CSS
.product-selection {
  .mat-button-toggle-group-appearance-standard {
    border: 1px solid #444444 !important;
    .mat-button-toggle + .mat-button-toggle {
      border-left: 1px solid #444444 !important;
    }
  }
  .mat-button-toggle-button,
  .mat-button-toggle-focus-overlay {
    height: 89px;
    width: 154px !important;
    border-radius: 3px;

    &:hover {
      background-color: #353839;
      font-weight: bold;
      color: #ffffff;
    }
    .mat-button-toggle-label-content {
      white-space: normal;
      line-height: initial;
    }
  }
  .mat-button-toggle-checked {
    background-color: #353839;
    font-weight: bold;
    color: #ffffff !important;
  }
  .logical-buttons-group {
    .mat-button-toggle-button,
    .mat-button-toggle-focus-overlay {
      height: 40px;
      width: 55px !important;
    }
  }
  .mat-radio-container {
	transform: scale(1.2);
  }
  .mat-checkbox-inner-container {
	transform: scale(1.5);
  }
}

.product-configuration-options-table{
  .mat-radio-label{
    white-space: -moz-pre-wrap !important;
    white-space: -webkit-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  @media all and (max-width: 2000px) and (min-width: 961px) and (-ms-high-contrast:none)
  /* IE11 */
  {
  .mat-radio-label-content{
   width:265px; 
   }
  }
}
@media(max-width:992px){
  .ButtonGroup{
    flex-direction: column !important;
  }
  .product-selection .mat-button-toggle-button{
    border-bottom: 1px solid #353839 !important;
  }
  .mat-radio-label{
    white-space: -moz-pre-wrap !important;
    white-space: -webkit-pre-wrap !important;
    white-space: -pre-wrap !important;
    white-space: -o-pre-wrap !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    white-space: normal !important;
  }
}